.weitere-news-bl {
	display: flex;
	justify-content: space-between;
	width: 100%;
	color: var(--color-white);
	min-height: 450px;

	@media (max-width: 767px) {
		flex-direction: column-reverse;
		padding: 0 15px;
	}

	.witer-bl-left {
		background: #004281;
		padding: 50px;
		width: 40%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: end;

		@media (max-width: 1199px) {
			width: 50%;
		}

		@media (max-width: 767px) {
			width: 100%;
		}

		.btn-link {
			float: right;
			color: var(--color-white);
			text-decoration: none;
			font-size: 28px;

			&:hover {
				opacity: 0.5;
			}
		}

		h3 {
			color: var(--color-white);
		}
	}

	.weitere-bl {
		width: calc(100% - 40%);
		background-image: url(RESOURCE/img/weitere_bg_img.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		@media (max-width: 1199px) {
			width: calc(100% - 50%);
		}

		@media (max-width: 767px) {
			width: 100%;
			height: 400px;
		}

	}
}